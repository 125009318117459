import {
	required,
	requiredIf
} from "vuelidate/lib/validators";

export default {
	name: "admin-formulario-banner",
	data() {
		return {
			blLoading: true,
			blDialogExcluir: false,
			blExcluindo: false,
			blErro: false,
			blSalvando: false,
			obForm: {
				id: 0,
				titulo: "",
				imagem: null,
				link: "",
				prioridade: 0
			}
		};
	},
	validations: {
		obForm: {
			link: {
				required
			},
			titulo: {
				required
			},
			imagem: {
				required: requiredIf(function (form) {
					return this.isLoggedIn && form.change_password
				})
			}

		}
	},
	created() {
		if (this.$route.params.id)
			this.obter(this.$route.params.id);
		else
			this.obter(0);
	},
	methods: {
		obter(id) {
			this.$root.$api.get("banner/obter/" + id).then(response => {
				console.log(response);
				if (response.retorno) {
					this.$root.$utils.preencheFormularios(
						this.obForm,
						response.retorno
					);
				}

				this.blLoading = false;
			});
		},
		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
		},
		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}
		},
		submit() {
			this.validarFormulario();

			if (this.blErro) {
				return false;
			}

			this.blSalvando = true;

			this.$root.$api
				.file("banner/salvar", this.obForm)
				.then(response => {
					this.blSalvando = false;

					this.$router.push({
						name: "admin-banners"
					});
				});
		},
		excluir() {
			this.blExcluindo = true;

			this.$root.$api
				.delete("banner/excluir/" + this.obForm.id)
				.then(response => {
					this.blDialogExcluir = false;

					this.$router.push({
						name: "admin-banners"
					});
				});
		}
	}
};